import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../App.css';
import Quote from './Quote';

function App() {

    return (
        <Container>
            <Row>
                <Col>
                    <Row>
                        <Col lg="9">
                            <img src="logo.png"/>
                            <h1>Maintenance</h1>
                            <p>Vous pouvez nous contacter via le formulaire de <a href="https://www.deli-street.be/contact">contact</a></p>
                        </Col>
                    </Row>
                    {/* <Quote></Quote>*/}
                </Col>
            </Row>
        </Container>
    );
}

export default App;
