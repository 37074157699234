import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import axios from 'axios';
import { GoogleMap, LoadScript, Marker, DistanceMatrixService, Autocomplete } from '@react-google-maps/api';
import * as Datetime from 'react-datetime';
import business from 'moment-business';
import {loadStripe} from '@stripe/stripe-js';

const recaptchaRef = React.createRef();
const stripePromise = loadStripe('pk_live_vuqOKbkGhGylZT1ksx0YvV7000ab3H79BT');
class Quote extends React.Component {
    constructor(props) {
        super(props);
        const today = new Date();
        this.state = {
            step: 1,
            date: '',
            time: '',
            guests: 0,
            type: '',
            distance: {value: 0},
            center: {
                lat: 50.665873,
                lng: 4.419619
            },
            zoom: 11,
            address: '',
            destination: null,
            delistreet: '77 chemin du lanternier, 1380 lasne',
            libraries: ['places'],
            description: '',
            email: '',
            name: '',
            telephone: '',
            total: 0,
            availability: true,
            isWeekDay: true,
            howFindUs: '',
            types: [],
            options: [],
            eventTypes: [],
            dishes: {burgers: [], entrees: [], deserts: []},
            burgersSelected: [],
            desertSelected: null,
            entreeSelected: null,
            currentFormula: null,
            timing: '',
            invoincingAddress: '',
            startHour: '',
            tva: '',
            burgersNumber: 0,
            cancellation: false,
            summary: '',
            minBookingDate: new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
            termsAccepted: false,
            roomForTruckAccepted: false
        };
        this.autocomplete = null

        this.onLoad = this.onLoad.bind(this)
        this.onPlaceChanged = this.onPlaceChanged.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleBurgerChange = this.handleBurgerChange.bind(this);
        this.handleEntreeChange = this.handleEntreeChange.bind(this);
        this.handleDesertChange = this.handleDesertChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleTimingChange = this.handleTimingChange.bind(this);
        this.handleCancellationChange = this.handleCancellationChange.bind(this);
        this.handleNextStep = this.handleNextStep.bind(this);
        this.handlePreviousStep = this.handlePreviousStep.bind(this);
        this.getBurgerPrice = this.getBurgerPrice.bind(this);
    }

    componentDidMount() {
        axios.all([
            axios.get(`${process.env.REACT_APP_API_HOST}api/v1/options/formula`),
            axios.get(`${process.env.REACT_APP_API_HOST}api/v1/options/origin`),
            axios.get(`${process.env.REACT_APP_API_HOST}api/v1/options/eventType`),
            axios.get(`${process.env.REACT_APP_API_HOST}api/v1/dishes`),
        ])
        .then(axios.spread((formula, origin, eventTypes, dishes) => {
            const entreesSelected = {};
            dishes.data.filter(dish => dish.type === 'entree').map(entree => {
                entreesSelected[entree.id] = {price: 0, number: 0, entree: null};
            });
            this.setState({
                types: formula.data,
                options: origin.data,
                eventTypes: eventTypes.data,
                dishes: {
                    burgers: dishes.data.filter(dish => dish.type === 'burger'),
                    entrees: dishes.data.filter(dish => dish.type === 'entree'),
                    deserts: dishes.data.filter(dish => dish.type === 'desert'),
                },
                entreeSelected: entreesSelected
            });
        }));
    }

    handleSubmit(event) {
        event.preventDefault();
        if (this.state.burgersNumber < 40) {
            alert('Vous devez commander au moins 40 burgers.');
            document.getElementById('burgersNumber').focus();
            this.setState({step: 3});
            return false;
        }
        if (this.state.availability === true && this.state.termsAccepted === true && this.state.roomForTruckAccepted === true) {
            const dishes = [];
            this.state.burgersSelected.forEach(burgerId => {
                dishes.push({id: burgerId, type: 'burger'});
            });
            for (const id in this.state.entreeSelected) {
                const entree = this.state.entreeSelected[id];
                if (entree.entree != null) {
                    dishes.push({id: entree.entree.id, type: 'entree', number: entree.number});
                }
            }
            const quote = {
                date: this.state.date + ' ' + this.state.time,
                guests: this.state.guests,
                address: this.state.address,
                email: this.state.email,
                name: this.state.name,
                telephone: this.state.telephone,
                distance: this.state.distance.value / 1000,
                total: this.state.total,
                howFindUs: this.state.howFindUs,
                event_type: this.state.eventType,
                dishes: dishes,
                timing: this.state.timing,
                tva: this.state.tva,
                invoincingAddress: this.state.invoincingAddress,
                startHour: this.state.startHour,
                firstName: this.state.firstName,
                cancellation: this.state.cancellation,
                summary: this.state.summary,
                burgersNumber: this.state.burgersNumber
            };
            axios.post(`${process.env.REACT_APP_API_HOST}api/v1/quote`, quote)
            .then(resp => {
                const sessionId = resp.data.session.id;
                stripePromise.then(stripe => {
                    stripe.redirectToCheckout({
                        // Make the id field from the Checkout Session creation API response
                        // available to this file, so you can provide it as parameter here
                        // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
                        sessionId: sessionId
                      }).then(function (result) {
                        // If `redirectToCheckout` fails due to a browser or network
                        // error, display the localized error message to your customer
                        // using `result.error.message`.
                    });
                });
            });
        } else {
            alert('Date indisponible ou cgv pas acceptés');
            event.preventDefault();
        }
    }

    handleInputChange(event) {
        const calculationFields = [];
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        }, () => {
            if (calculationFields.indexOf(target.name) !== -1 && (this.state.burgersNumber != null && Number(this.state.burgersNumber) > 0)) {
                this.calculateQuote();
            }
        });
    }

    handleBurgerChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const burger = this.state.dishes.burgers.filter(burger => burger.id.toString() === target.id)[0];
        const selected = this.state.burgersSelected;
        if (selected.length >= 3 && value === true) {
            target.value = false;
            alert('vous ne pouvez choisir que 3 burgers');
            event.preventDefault();
            return;
        } else {
            if (selected.indexOf(burger.id) === -1 && value === true) {
                selected.push(burger.id);
            } else {
                if (value === false) {
                    selected.splice(selected.indexOf(burger.id), 1);
                }
            }
            this.setState({
                burgersSelected: selected
            }, () => {
                let formula = '';
                this.state.burgersSelected.forEach(id => {
                    const burger = this.state.dishes.burgers.filter(burg => id === burg.id)[0];
                    if (burger.sub_type === 'basic' && formula === '') {
                        formula = burger.sub_type;
                    }
                    if (burger.sub_type === 'medium' && formula !== 'high') {
                        formula = burger.sub_type;
                    }
                    if (burger.sub_type === 'high') {
                        formula = burger.sub_type;
                    }
                });
                axios.get(`${process.env.REACT_APP_API_HOST}api/v1/setting/${formula}`)
                .then(response => {
                    this.setState({currentFormula: response.data}, () => {
                        this.calculateQuote();
                    });
                });
            });
        }
    }

    handleEntreeChange(event) {
        const target = event.target;
        const value = target.value;
        const entree = this.state.dishes.entrees.filter(entree => entree.id.toString() === target.id.replace('entree', ''))[0];
        const entreeSelected = this.state.entreeSelected;
        axios.get(`${process.env.REACT_APP_API_HOST}api/v1/setting/${entree.sub_type}`)
            .then(response => {
                entreeSelected[Number(target.id.replace('entree', ''))] = {number: Number(value), entree: entree, price: Number(response.data.value)};
                this.setState({
                    entreeSelected: entreeSelected
                }, () => {
                    this.calculateQuote();
                });
            });
    }

    handleDesertChange(event) {
        const target = event.target;
        const desert = this.state.dishes.deserts.filter(desert => desert.id.toString() === target.id)[0];
        this.setState({desertSelected: Number(target.id)});
        axios.get(`${process.env.REACT_APP_API_HOST}api/v1/setting/${desert.sub_type}`)
            .then(response => {
                this.setState({
                    currentDesert: response.data
                }, () => {
                    this.calculateQuote();
                });
            });
    }

    handleDateChange(event) {
        const obj = {};
        if (event.target.id === 'time') {
            obj.time = event.target.value;
        } else {
            obj.date = event.target.value;
        }
        this.setState(obj, () => {
            this.checkAvailability();
        });
        /*
        if (typeof dateObj === 'object' && dateObj.isValid() === true) {
            this.setState({
                date: dateObj.format('YYYY-MM-DD HH:mm:ss'),
                isWeekDay: business.isWeekDay(dateObj)
            }, () => {
                this.checkAvailability();
            });
        }
        */
    }

    handleTimingChange(event) {
        this.setState({
            timing: event.target.value
        }, () => {
            this.checkAvailability();
        });
    }

    onLoad(autocomplete) {
        this.autocomplete = autocomplete
    }
    
    onPlaceChanged() {
        if (this.autocomplete !== null) {
            this.setState({
                address: this.autocomplete.getPlace().formatted_address,
                destination: {lat: this.autocomplete.getPlace().geometry.location.lat(), lng: this.autocomplete.getPlace().geometry.location.lng()},
                center: {
                    lat: this.autocomplete.getPlace().geometry.location.lat(),
                    lng: this.autocomplete.getPlace().geometry.location.lng()
                },
                invoincingAddress: this.autocomplete.getPlace().formatted_address
            });
        }
    }

    distanceCallback = response => {
        if (response !== null) {
            this.setState({distance: response.rows[0].elements[0].distance});
        }
    }

    calculateQuote() {
        let total = 0;
        let pricePerBurger = 0;
        let pricePerPerson = 0;
        let pricePerKm = 0;
        let travellingExpenses = 0;

        axios.all([
            axios.get(`${process.env.REACT_APP_API_HOST}api/v1/setting/distance`),
            axios.get(`${process.env.REACT_APP_API_HOST}api/v1/setting/freekm`)
        ])
        .then(axios.spread((distance, freekm) => {
            total = 150;
            if (this.state.currentFormula !== null) {
                pricePerBurger = Number(this.state.currentFormula.value);
            }
            pricePerPerson = 0;
            pricePerKm = Number(distance.data.value);
            if (freekm.data.value < (Number(this.state.distance.value) / 1000)) {
                travellingExpenses = ((Number(this.state.distance.value) / 1000) - freekm.data.value) * 2 * pricePerKm;
            }
            total = Number(this.state.burgersNumber) * pricePerBurger + travellingExpenses + (pricePerPerson * this.state.guests);
            for (let key in this.state.entreeSelected) {
                if (this.state.entreeSelected[key].number > 0) {
                    total += this.state.entreeSelected[key].number * this.state.entreeSelected[key].price;
                }
            }
            if (this.state.cancellation === true) {
                total = total * 1.1;
            }
            this.setState({total: total}, () => {
                let html = '';
                html = 'Privatisation du food truck : 150€<br/>';
                total = 150;
                if (this.state.currentFormula !== null) {
                    pricePerBurger = Number(this.state.currentFormula.value);
                }
                pricePerPerson = 0;
                pricePerKm = Number(distance.data.value);
                if (freekm.data.value < (Number(this.state.distance.value) / 1000)) {
                    travellingExpenses = ((Number(this.state.distance.value) / 1000) - freekm.data.value) * 2 * pricePerKm;
                }
                total += Number(this.state.burgersNumber) * pricePerBurger + travellingExpenses + (pricePerPerson * this.state.guests);
                if (this.state.currentFormula !== null) {
                    html += `Formule choisie : ${this.state.currentFormula.value} €<br/>`;
                    html += `Nombre de burgers : ${this.state.burgersNumber}<br/>`;
                }
                for (let key in this.state.entreeSelected) {
                    if (this.state.entreeSelected[key].number > 0) {
                        total += this.state.entreeSelected[key].number * this.state.entreeSelected[key].price;
                        html += `${this.state.entreeSelected[key].number} ${this.state.entreeSelected[key].entree.name} : ${this.state.entreeSelected[key].number * this.state.entreeSelected[key].price} €<br/>`;
                    }
                }
                if (freekm.data.value < (Number(this.state.distance.value) / 1000)) {
                    html += `Frais de déplacement : ${(((Number(this.state.distance.value) / 1000) - freekm.data.value) * 2 * pricePerKm).toFixed(2)} €<br/>`;
                }
                if (this.state.cancellation === true) {
                    total = total * 1.1;
                    html += `Option d'annulation : ${(total / 10).toFixed(2)} €<br/>`;
                }
                html += `Total : ${total.toFixed(2)} €<br/>`;
                this.setState({summary: html, total: total.toFixed(2)});
            });
        }));
    }

    checkAvailability() {
        axios.get(`${process.env.REACT_APP_API_HOST}api/v1/quote/availability/${this.state.date} ${this.state.time}`)
        .then((resp) => {
            if (resp.data.free === false) {
                alert('Veuillez choisir une autre date/heure');
            }
            this.setState({availability: resp.data.free});
        });
    }

    handleCancellationChange(event) {
        this.setState({cancellation: event.target.checked}, () => {
            this.calculateQuote();
        });
    }

    handleNextStep() {
        this.setState({step: this.state.step + 1});
    }

    handlePreviousStep() {
        this.setState({step: this.state.step - 1});
    }

    getBurgerPrice(dish) {
        switch(dish.sub_type) {
            default:
            case 'basic':
                return '8.5 €';
            case 'medium':
                return '9 €';
            case 'high':
                return '10 €';
        }
    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col lg="9">
                        <img src="logo.png"/>
                        <ProgressBar animated now={this.state.step * 25} className="m10"/>
                        <div className={this.state.step === 1 ? '' : 'display-none'} style={{ height: '200px', width: '100%', margin: '0 0 1rem 0'}}>
                            <LoadScript
                                id='script-loader'
                                libraries={this.state.libraries}
                                googleMapsApiKey='AIzaSyB-NZyfPaJnamhkMJnQLjOZqBzYlrqesCg'
                            >
                                <GoogleMap
                                id='example-map'
                                mapContainerStyle={{ height: '100%', width: '100%' }}
                                center={this.state.center}
                                zoom={this.state.zoom}
                                >
                                    <Autocomplete
                                        onLoad={this.onLoad}
                                        onPlaceChanged={this.onPlaceChanged}
                                    >
                                        <input
                                        type="text"
                                        placeholder="Adresse exacte de prestation"
                                        style={{
                                            boxSizing: `border-box`,
                                            border: `1px solid transparent`,
                                            width: `240px`,
                                            height: `32px`,
                                            padding: `0 12px`,
                                            borderRadius: `3px`,
                                            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                            fontSize: `14px`,
                                            outline: `none`,
                                            textOverflow: `ellipses`,
                                            position: "absolute",
                                            left: "50%",
                                            marginLeft: "-120px"
                                        }}
                                        />
                                    </Autocomplete>
                                    {this.state.destination !== null && (
                                        <Marker key='marker' position={{ lat: this.state.destination.lat, lng: this.state.destination.lng}}></Marker>
                                    )
                                    }
                                    {
                                        this.state.address !== '' && (
                                        <DistanceMatrixService 
                                        options={{
                                            destinations: [this.state.address],
                                            origins: [this.state.delistreet],
                                            travelMode: 'DRIVING',
                                        }}
                                        onLoad={this.distance}
                                        callback={this.distanceCallback}/>
                                    )}
                                </GoogleMap>
                            </LoadScript>
                        </div>
                        <Form onSubmit={this.handleSubmit} noValidate>
                            <div className={this.state.step === 2 ? '' : 'display-none'}>
                                <Form.Group controlId="name">
                                    <Form.Label>Nom</Form.Label>
                                    <Form.Control type="text" name="name" placeholder="Nom" value={this.state.name} onChange={this.handleInputChange}/>
                                </Form.Group>
                                <Form.Group controlId="firstName">
                                    <Form.Label>Prénom</Form.Label>
                                    <Form.Control type="text" name="firstName" placeholder="Prénom" value={this.state.firstName} onChange={this.handleInputChange}/>
                                </Form.Group>
                                <Form.Group controlId="email">
                                    <Form.Label>Mail</Form.Label>
                                    <Form.Control type="email" name="email" placeholder="Mail" value={this.state.email} onChange={this.handleInputChange}/>
                                </Form.Group>
                                <Form.Group controlId="telephone">
                                    <Form.Label>Téléphone</Form.Label>
                                    <Form.Control type="text" name="telephone" placeholder="Telephone" value={this.state.telephone} onChange={this.handleInputChange}/>
                                </Form.Group>
                                <Form.Group controlId="date">
                                    <Form.Label>Date</Form.Label>
                                    <Form.Control type="date" name="date" min={this.state.minBookingDate} placeholder="Date" value={this.state.date} onChange={this.handleDateChange}/>
                                </Form.Group>
                                <Form.Group controlId="time">
                                    <Form.Label>Heure</Form.Label>
                                    <Form.Control type="time" name="time" placeholder="Heure" value={this.state.time} onChange={this.handleDateChange}/>
                                </Form.Group>
                                <Form.Group controlId="guests">
                                    <Form.Label>Nombre d'invités</Form.Label>
                                    <Form.Control type="number" name="guests" placeholder="Nombre d'invités" value={this.state.guests} onChange={this.handleInputChange}/>
                                </Form.Group>
                                <Form.Group controlId="invoincingAddress">
                                    <Form.Label>Adresse de facturation</Form.Label>
                                    <Form.Control type="text" name="invoincingAddress" placeholder="Adresse de facturation" value={this.state.invoincingAddress} onChange={this.handleInputChange}/>
                                </Form.Group>
                                <Form.Group controlId="address">
                                    <Form.Label>Adresse de prestation</Form.Label>
                                    <Form.Control type="text" readOnly="readOnly" name="address" placeholder="Adresse de facturation" value={this.state.address} />
                                </Form.Group>
                                <Form.Group controlId="tva">
                                    <Form.Label>TVA</Form.Label>
                                    <Form.Control type="text" name="tva" placeholder="TVA" value={this.state.tva} onChange={this.handleInputChange}/>
                                </Form.Group>
                                <Form.Group controlId="howFindUs">
                                    <Form.Label>Comment connaissez-vous Déli-street</Form.Label>
                                    <Form.Control as="select" name="howFindUs" value={this.state.howFindUs} onChange={this.handleInputChange}>
                                        <option value="">Choisissez une valeur</option>
                                        {this.state.options.map((option, index) => {
                                            return <option key={option.id} value={option.value}>{option.label}</option>
                                        })}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group controlId="eventType">
                                    <Form.Label>Quel type d’événement organisez-vous</Form.Label>
                                    <Form.Control as="select" name="eventType" value={this.state.eventType} onChange={this.handleInputChange}>
                                        <option value="">Choisissez une valeur</option>
                                        {this.state.eventTypes.map((option, index) => {
                                            return <option key={option.id} value={option.value}>{option.label}</option>
                                        })}
                                    </Form.Control>
                                </Form.Group>
                            </div>
                            <div className={this.state.step === 3 ? '' : 'display-none'}>
                                <Form.Group controlId="burgersNumber">
                                    <Form.Label>Nombre de burgers souhaités (minimum 40)</Form.Label>
                                    <Form.Control type="number" name="burgersNumber" min="40" placeholder="Nombre de burgers souhaités" value={this.state.burgersNumber} onChange={this.handleInputChange}/>
                                    <Form.Text className="text-muted">
                                        Attention, tenez compte du fait que certaines personnes en prendront peut-être un deuxième même si nos burgers ont la réputation d’être copieux.
                                        <br/>Il ne faut pas préciser le nombre de chaque burger, juste le nombre total de burgers désirés. Nous prévoyons en suffisance de chaque sorte.<br/>
                                        Vous pouvez augmenter le nombre de burgers 7 jours avant votre évènement en nous contactant.
                                    </Form.Text>
                                </Form.Group>
                                    <h2>
                                        Choisissez vos burgers 
                                        {this.state.currentFormula !== null && (
                                            <span>({this.state.currentFormula.value} €)</span>
                                        )
                                        }
                                        :
                                    </h2>
                                <div>
                                    {this.state.dishes.burgers.map((dish, index) => {
                                        return <Form.Check custom inline name='burger' label={dish.name + ' : ' + dish.description + ' ('+ this.getBurgerPrice(dish) +')'} type='checkbox' key={dish.id} id={dish.id} value={this.state.burgersSelected.indexOf(dish.id) !== -1} onChange={this.handleBurgerChange}/>
                                    })}
                                </div>
                                <h2>Souhaitez-vous compléter votre menu avec des entrées ?</h2>
                                <div>
                                    {this.state.dishes.entrees.map((dish, index) => {
                                        return (
                                            <Form.Group>
                                                <Form.Label>{dish.name + ' : ' + dish.description}</Form.Label>
                                                <Form.Control type="number" name={'entree[' + dish.id + ']'} min='40' key={dish.id} id={'entree' + dish.id} onChange={this.handleEntreeChange}/>
                                            </Form.Group>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className={this.state.step === 4 ? '' : 'display-none'}>
                                    <div dangerouslySetInnerHTML={{__html: this.state.summary}}></div>
                                    <Form.Check custom inline name='termsAccepted' label={(<>J'ai lu et j'accepte les <a href="https://www.deli-street.be/cgv-et-confidentialite" target="_blank">conditions de vente de Deli-street</a></>)} type='checkbox' key='termsAccepted' id='termsAccepted' value={this.state.termsAccepted} onChange={this.handleInputChange}/>
                                    <Form.Check custom inline name='roomForTruckAccepted' label={(<>Je confirme avoir un passage dégagé de 3 mètres de large sur 3 mètres de haut pour le passage du foodtruck d'une longueur de 7 mètres.<br/>Emplacement : dimension minimum : 8m x 5m (L x l) / Poids 3500 kg.<br/>Besoin électrique : 2 x 220 volts / 16 Ampères</>)} type='checkbox' key='roomForTruckAccepted' id='roomForTruckAccepted' value={this.state.roomForTruckAccepted} onChange={this.handleInputChange}/>
                            </div>
                            <div>
                                {this.state.step > 1 && (
                                    <Button variant="secondary" onClick={this.handlePreviousStep} className="float-left">
                                        Précédent
                                    </Button>
                                )}
                                {this.state.step < 4 && (
                                    <Button variant="primary" onClick={this.handleNextStep} className="float-right">
                                        Suivant
                                    </Button>
                                )}
                                {this.state.step === 4 && (
                                    <Button variant="primary" type="submit" className="float-right" disabled={!this.state.availability}>
                                        Réserver
                                    </Button>
                                )}
                            </div>
                        </Form>
                    </Col>
                    <Col lg="3">
                        <Navbar sticky="top">
                            {this.state.step > 1 && this.state.step < 4 && (
                            <div dangerouslySetInnerHTML={{__html: this.state.summary}}>
                            </div>
                            )}
                        </Navbar>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default Quote;